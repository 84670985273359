<template>
  <div class="">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'CallingCardsPool'">
    </breadcrumb-base>
    <div class="flex flex-row">
      <div class="w-full md:w-2/5">
        <vs-table-modified ref="table"
                           :data="unAssignedProducts"
        >

          <template slot="thead">
            <vs-th>
              <vs-checkbox @input="onUnAssignedProductCheckedAll" v-model="unAssignedCheckedAll" ></vs-checkbox>
            </vs-th>
            <vs-th >{{$t('Image')}}</vs-th>
            <vs-th>{{$t('Denomination')}}</vs-th>
            <vs-th>{{ $t('Action') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data" >

              <vs-td>
                <vs-checkbox v-model="unAssignedChecks[tr.configuration_id.toString()]" :vs-value="tr.configuration_id.toString()"></vs-checkbox>
              </vs-td>

              <vs-td>
                <img :src="tr.logo" :alt="tr.product_name" class="h-12 product-image"/>
              </vs-td>

              <vs-td>
                <div class="vs-col">
                  <p class=""
                     style="font-weight: bold;">{{ tr.value | germanNumberFormat}}
                  </p>
                </div>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <vs-button radius color="success"
                           type="filled" icon-pack="feather"
                           icon="icon-plus"
                           @click="assignProduct(tr)"></vs-button>
              </vs-td>

            </vs-tr>
            </tbody>
          </template>
        </vs-table-modified>
      </div>
      <div class="w-full md:w-1/5 flex  justify-center">
        <div class="px-4 w-full flex flex-col">
          <div class="mt-4 mb-2 w-full sm:mb-4 mx-auto ">
            <button
              @click="assignMultipleProducts"
              :disabled="unAssignedSelectedProductsLength === 0"
              type="submit"
              class="bg-temp3-success inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
              :class="unAssignedSelectedProductsLength === 0 ? 'bg-opacity-50' : ''"
            >
              {{ $t('Assign') }} {{ unAssignedSelectedProductsLength }} {{ $t('Products') }}
            </button>
          </div>
          <div class="mt-4 mb-2 w-full sm:mb-4 mx-auto">
            <button
              @click="unAssignMultipleProducts"
              :disabled="assignedSelectedProductsLength === 0"
              type="submit"
              class="bg-red-600 inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
              :class="assignedSelectedProductsLength === 0 ? 'bg-opacity-50' : ''"
            >
              {{ $t('Remove') }} {{ assignedSelectedProductsLength }} {{ $t('Products') }}
            </button>
          </div>

        </div>
      </div>
      <div class="w-full md:w-2/5">
        <vs-table-modified ref="table"
                           :data="assignedProducts"
        >

          <template slot="thead">
            <vs-th>
              <vs-checkbox @input="assignedProductCheckedAll" v-model="assignedCheckedAll" ></vs-checkbox>
            </vs-th>
            <vs-th >{{$t('Image')}}</vs-th>
            <vs-th>{{$t('Denomination')}}</vs-th>
            <vs-th>{{ $t('Action') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data" >

              <vs-td>
                <vs-checkbox v-model="assignedChecks[tr.configuration_id.toString()]" :vs-value="tr.pap_id.toString()"></vs-checkbox>
              </vs-td>

              <vs-td>
                <img :src="tr.logo" :alt="tr.product_name" class="h-12 product-image"/>
              </vs-td>

              <vs-td>
                <div class="vs-col">
                  <p class=""
                     style="font-weight: bold;">{{ tr.value | germanNumberFormat}}
                  </p>
                </div>
              </vs-td>

              <vs-td class="whitespace-no-wrap">

                <vs-button radius color="danger" type="filled" icon-pack="feather"
                           icon="icon-minus" @click="unAssignProduct(tr)"></vs-button>
              </vs-td>

            </vs-tr>
            </tbody>
          </template>
        </vs-table-modified>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoolPAD",
  data() {
    return {
      assignedProducts: [],
      unAssignedProducts: [],
      unAssignedChecks: {},
      assignedChecks: {},
      unAssignedCheckedAll: false,
      assignedCheckedAll: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Calling Cards Pool', i18n: 'CallingCardsPool', url: '/inventory/pool' },
        { title: 'Calling Cards Pool Update', i18n: 'CallingCardsPoolUpdate', active: true},
      ],
    }
  },
  props: {
    poolId: {
      default: '',
      type: Number | String
    }
  },
  computed: {

    unAssignedSelectedProductsLength() {
      return Object.values(this.unAssignedChecks).filter((v) => v != null).length
    },
    assignedSelectedProductsLength() {
      return Object.values(this.assignedChecks).filter((v) => v != null).length
    },
  },
  methods: {
    onUnAssignedProductCheckedAll(value) {
      if (value) {
        const obj = {}
        this.unAssignedProducts.forEach((k) => {
          obj[k.configuration_id] = k.configuration_id.toString()
        })
        this.unAssignedChecks = obj
      } else {
        this.unAssignedChecks = {}
      }
    },
    assignedProductCheckedAll(value) {
      if (value) {
        const obj = {}
        this.assignedProducts.forEach((k) => {
          obj[k.configuration_id] = k.pap_id.toString()
        })
        this.assignedChecks = obj
      } else {
        this.assignedChecks = {}
      }
    },
    assignProduct(p) {
      let payload = [{
        mode: 0,
        configuration_id: p.configuration_id
      }]
      this.$vs.loading()
      this.$store.dispatch('callingCards/fetchSingleCallingCardPool', {
        payload: {
          pool_id: this.poolId,
          pool_json: JSON.stringify(payload)
        }
      })
        .then((data) => {
          this.updateVariables(data);
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.showError(error.response.data.message || error.message)
        })
    },
    assignMultipleProducts() {
      let payload = []
      Object.entries(this.unAssignedChecks).forEach((a) => {
        if (a[1]) {
          payload.push(
            {
              'mode': 0,
              'configuration_id': a[0],
            },
          )
        }
      })

      this.$vs.loading()
      this.$store.dispatch('callingCards/fetchSingleCallingCardPool', {
        payload: {
          pool_id: this.poolId,
          pool_json: JSON.stringify(payload)
        }
      })
        .then((data) => {
          this.updateVariables(data);
          this.$vs.loading.close()
          this.errorFetching = false
          this.unAssignedChecks = {}
          this.unAssignedCheckedAll = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.showError(error.response.data.message || error.message)
        })
    },
    unAssignProduct(p) {
      let payload = [{
        mode: 1,
        pap_id: p.pap_id,
        configuration_id: p.configuration_id
      }]
      this.$vs.loading()
      this.$store.dispatch('callingCards/fetchSingleCallingCardPool', {
        payload: {
          pool_id: this.poolId,
          pool_json: JSON.stringify(payload)
        }
      })
        .then((data) => {
          this.updateVariables(data);
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.showError(error.response.data.message || error.message)
        })
    },
    unAssignMultipleProducts() {
      let payload = []
      Object.entries(this.assignedChecks).forEach((a) => {
        if (a[1]) {
          payload.push(
            {
              'mode': 1,
              'pap_id': a[1],
              'configuration_id': a[0],
            },
          )
        }
      })
      this.$vs.loading()
      this.$store.dispatch('callingCards/fetchSingleCallingCardPool', {
        payload: {
          pool_id: this.poolId,
          pool_json: JSON.stringify(payload)
        }
      })
        .then((data) => {
          this.updateVariables(data);
          this.$vs.loading.close()
          this.errorFetching = false
          this.assignedChecks = {}
          this.assignedCheckedAll = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.showError(error.response.data.message || error.message)
        })
    },
    showError(msg) {
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    updateVariables(data) {
      this.assignedProducts = data.assigned_products;
      this.unAssignedProducts = data.unassigned_products;
    } ,
    fetchPoolProducts() {
      this.$vs.loading()
      this.$store.dispatch('callingCards/fetchSingleCallingCardPool', {
        payload: {
          pool_id: this.poolId,
        }
      })
        .then((data) => {
          this.updateVariables(data);
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.showError(error.response.data.message || error.message)
        })
    },
  },
  mounted() {
    this.fetchPoolProducts();
  }
}
</script>

<style scoped>

</style>
